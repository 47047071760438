@import 'ngx-toastr/toastr';
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Omnes';
  src: url('assets/fonts/Omnes Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Omnes';
  src: url('assets/fonts/Omnes ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Omnes';
  src: url('assets/fonts/Omnes Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Omnes';
  src: url('assets/fonts/Omnes Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Omnes';
  src: url('assets/fonts/Omnes Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Omnes';
  src: url('assets/fonts/Omnes SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Omnes';
  src: url('assets/fonts/Omnes Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Omnes';
  src: url('assets/fonts/Omnes Black.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

body {
  height: 100vh;
  /* background: #edf2ff; */
  background: var(--bg-color-fondo);
  margin: 0;
  font-family: 'Omnes', sans-serif;
}
html {
  height: 100%;
}
:root {
  --h-navbar: 100px;
  --bg-color-primary: #51af3d;
  --bg-color-secondary: #2e7523;
  --bg-color-info: #138900;
  --bg-color-warn: #ffaa4f;
  --bg-color-cancel: #e04444;
  --bg-color-disabled: #d6d6d6;
  --bg-color-fondo: #e7f3e9;
  --bg-color-terc: rgba(89, 173, 86, 0.48);
}

.bg-color-primary {
  background-color: var(--bg-color-primary);
}
.color-primary {
  color: var(--bg-color-primary);
}
.bg-color-secondary {
  background-color: var(--bg-color-secondary);
}
.color-secondary {
  color: var(--bg-color-secondary);
}
.bg-color-info {
  background-color: var(--bg-color-info);
}
.bg-color-cancel {
  background-color: var(--bg-color-cancel);
}
.bg-color-disabled {
  background-color: var(--bg-color-disabled);
}
.bg-color-terc {
  background-color: var(--bg-color-terc);
}

.zoomable {
  transition: transform 0.2s;
  cursor: pointer;
}

.zoomed {
  transform: scale(2);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.45);
  display: grid;
  z-index: 9999;
  overflow: auto;
}

.overlay img {
  /* max-width: 90%;
  max-height: 90%; */
  max-width: none !important;
  margin: auto;
}

.zoom-button {
  position: fixed;
  font-size: 30px;
  padding: 10px 20px;
  cursor: pointer;
  background-color: #555;
  color: #fff;
  z-index: 10000;
}

.zoom-in-button {
  bottom: 50px;
  right: 75px;
}

.zoom-out-button {
  bottom: 50px;
  right: 20px;
}

.x-button {
  top: 50px;
  right: 45px;
}

.zoom-in-button:hover,
.zoom-out-button:hover,
.x-button:hover {
  background-color: #e9e9e9;
  color: #585858;
}

select:not([size]) {
  background-image: url('src/assets/svg/dropdown-svgrepo-com.svg');
  background-size: 1.75em;
  padding: 0.1rem 0 0.3rem 0;
}

.panel-class-modal .mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 1rem;
}

.mat-mdc-menu-content {
  padding: 0 !important;
}

.scroll-bar {
  scrollbar-width: thin;
  scrollbar-color: var(--bg-color-secondary) rgba(164, 164, 164, 0.333);
}

input:disabled,
textarea:disabled {
  color: #aaaaaa;
}
/* select:disabled {
  background: var(--bg-color-disabled);
} */

.margin-base-left {
  margin: 3rem 0 3rem 3rem;
}
.margin-base-right {
  margin: 3rem 3rem 3rem 0;
}
.margin-base-right-2 {
  margin: 2rem 2rem 2rem 0;
}

table {
  width: 100%;
}
.table-responsive {
  overflow: auto;
  width: 200px;
  min-width: 100%;
}
.class-table {
  border-radius: 1rem;
  box-shadow: 1px 1px 1px 1px var(--bg-color-primary);
  display: grid;
  background: #fff;
}
tr.mat-mdc-row {
  background: rgb(243, 243, 243);
}
tr.mat-mdc-row:nth-child(even) {
  background: rgb(240, 244, 254);
}
.mat-mdc-cell,
.mat-mdc-header-cell {
  font-family: 'Omnes', sans-serif;
}
.mat-mdc-cell {
  font-size: 1rem;
}
.mat-mdc-header-cell {
  color: var(--bg-color-secondary);
  font-size: 1rem;
  font-weight: 600 !important;
}

.mdc-fab--mini {
  width: 35px;
  height: 35px;
}

.mat-expansion-indicator::after {
  border-color: #000;
  padding: 6px !important;
}

.mat-mdc-tab {
  font-family: Omnes !important;
  letter-spacing: normal !important;
  font-size: 1rem !important;
}

.mat-mdc-checkbox label {
  font-family: Omnes !important;
  font-size: 1rem;
  font-weight: 500;
}

.popup-class {
  width: 25rem;
}
.title-class {
  font-size: 1.35rem;
}
.html-container {
  margin: 0.3em 1.6em;
  font-size: 1.3rem;
}
.confirm-button-class {
  background: var(--bg-color-primary) !important;
}
.confirm-button-class:focus {
  box-shadow: 0px 0px 4px 1px var(--bg-color-primary);
}
.cancel-button-class {
  background: var(--bg-color-cancel) !important;
}

.mat-mdc-dialog-container .mdc-dialog__content {
  font-family: Omnes !important;
}
.mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: var(--bg-color-primary) !important;
}
select:focus {
  --tw-ring-color: var(--bg-color-secondary);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--bg-color-secondary);
  border-color: var(--bg-color-secondary);
}

@media (width < 1020px) {
  .margin-base-left {
    margin: 6rem 1rem 0 1rem;
  }
  .margin-base-right {
    margin: 0 1rem 1rem 1rem;
  }
}
@media (width < 450px) {
  .div-content-datos {
    padding: 1rem 0;
  }
  .div-header-datos {
    width: 20rem;
  }
}
